const exhibitionList = {
  exhibition_1:
    "https://lookerstudio.google.com/embed/reporting/b93acfaa-27e2-496e-9950-05cc002d45fd/page/sfgyD",
  exhibition_2:
    "https://lookerstudio.google.com/embed/reporting/aef66226-710d-4db0-a61e-c158d690286c/page/sfgyD",
  exhibition_3:
    "https://lookerstudio.google.com/embed/reporting/a66ff5aa-e5e5-4e07-b930-8c7bc7f2489d/page/sfgyD",
  exhibition_4:
    "https://lookerstudio.google.com/embed/reporting/8d4880c3-d03e-4c57-b625-84d9ec168f7f/page/sfgyD",
  exhibition_5:
    "https://lookerstudio.google.com/embed/reporting/916d46ea-7164-4d74-8af8-cc2aafb8597c/page/sfgyD",
  exhibition_6:
    "https://lookerstudio.google.com/embed/reporting/a0acf089-9c17-4652-b693-0e30a556e906/page/sfgyD",
  exhibition_7:
    "https://lookerstudio.google.com/embed/reporting/17a77013-27b6-4ce8-ad5f-a12aa0ffe441/page/sfgyD",
  exhibition_8:
    "https://lookerstudio.google.com/embed/reporting/59b3b89b-648a-4a95-b87e-dd9d43909c04/page/sfgyD",
  exhibition_9:
    "https://lookerstudio.google.com/embed/reporting/d5ffb293-164d-4421-8c4a-daa1cf848ce8/page/sfgyD",
  exhibition_10:
    "https://lookerstudio.google.com/embed/reporting/4aaa837c-dd9f-406d-bfeb-7974d61b40de/page/sfgyD",
  exhibition_11:
    "https://lookerstudio.google.com/embed/reporting/1d23a690-adc4-4783-9fff-c3c23321ac1b/page/sfgyD",
};

export default exhibitionList;
